<template>
  <div>
    <b-card
      :title="
        type === 'add'
          ? 'Подключение ' + (isMainInstance ? 'Webhook' : '1C: Фитнес Клуб')
          : 'Редактировать ' + (isMainInstance ? 'Webhook' : '1C: Фитнес Клуб')
      "
      class="col col-md-6"
      v-if="type === 'add' || getCurrentIntegration"
    >
      <b-row>
        <b-col md="12" class="mb-2">
          <label for="webhook-name"> Название: </label>
          <b-form-input
            placeholder="Введите название"
            id="webhook-name"
            v-model="webhook.name"
          >
          </b-form-input>
        </b-col>
        <b-col md="12" class="mb-2">
          <label for="webhook-url"> URL: </label>
          <b-form-input
            placeholder="Введите URL"
            id="webhook-url"
            v-model="webhook.url"
          >
          </b-form-input>
        </b-col>
        <b-col>
          <b-button
            variant="primary"
            @click="type === 'add' ? webhookAdd() : webhookEdit()"
          >
            {{ type === "add" ? "Подключить Webhook" : "Редактировать" }}
          </b-button>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import { BCard, BRow, BCol, BFormInput, BButton } from "bootstrap-vue";
import { mapActions, mapGetters } from "vuex";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
  },
  data() {
    return {
      webhook: {
        type: "Webhook",
        url: "",
        name: "",
      },
      type: "add",
    };
  },
  metaInfo() {
    return {
      title: "moovee - Webhook интеграция",
    };
  },
  computed: {
    ...mapGetters("integrations", ["getCurrentIntegration"]),
    isMainInstance() {
      return !process.env.VUE_APP_ENV || process.env.VUE_APP_ENV !== "white";
    },
  },
  watch: {
    getCurrentIntegration(newVal) {
      this.webhook = newVal;
    },
  },
  methods: {
    ...mapActions("integrations", [
      "addIntegration",
      "requestCurrentIntegration",
      "editIntegration",
    ]),
    webhookAdd() {
      this.addIntegration(this.webhook).then(() => {
        this.$toast({
          component: ToastificationContent,
          props: {
            variant: "success",
            title: "Webhook подключен",
            icon: "BellIcon",
          },
        });
        this.$router.push({ name: "integrations" });
      });
    },
    webhookEdit() {
      this.editIntegration({
        id: this.$route.params.id,
        data: this.webhook,
      }).then(() => {
        this.$toast({
          component: ToastificationContent,
          props: {
            variant: "success",
            title: "Webhook редактирован",
            icon: "BellIcon",
          },
        });
        this.$router.push({ name: "integrations" });
      });
    },
  },
  created() {
    if (this.$route.params.id !== "add") {
      this.type = "edit";
      this.requestCurrentIntegration(this.$route.params.id);
    }
  },
};
</script>
